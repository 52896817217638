/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from "react";

import { getLocalize, I18nOptions } from "~/utils/i18n/localize";

import { useAppSelector } from "~/state/hooks";

import { TripStatus } from "~/gql/main/types.generated";

const labels = {
  saarDriver: {
    en: "Saar Driver",
    ar: " سار",
  },
  pageNotFound: {
    en: "Page not found",
    ar: "هذه الصفحة غير موجودة",
  },
  serverError: {
    en: "Server error",
    ar: "خطأ بالسيرفر",
  },
  tripManifest: {
    en: "Trip manifest",
    ar: "قائمة الرحلة",
  },
  "modal.appVersionUpdate.button.refresh": {
    en: "Refresh Now",
    ar: "تحديث",
  },
  "modal.appVersionUpdate.button.later": {
    en: "Later",
    ar: "لاحقا",
  },
  "modal.appVersionUpdate.button.later.waitMinutes": {
    en: "Wait ({{minutes}} minutes)",
    ar: "انتظر ({{minutes}} دقيقة)",
  },
  "modal.appVersionUpdate.button.later.waitSeconds": {
    en: "Wait ({{seconds}} seconds)",
    ar: "انتظر ({{seconds}} ثانية)",
  },
  "modal.appVersionUpdate.newVersion": {
    en: "New Version Available",
    ar: "يوجد إصدار جديد",
  },
  "modal.appVersionUpdate.updateMessage": {
    en: "Please refresh the page to get the latest version ({{version}}) of the dashboard or click on later to wait for {{minutes}} minutes until page refresh automatically.",
    ar: "يرجى تحديث الصفحة للحصول على أحدث إصدار ({{version}}) من لوحة التحكم أو انقر فوق لاحقًا للانتظار لمدة {{minutes}} دقيقة حتى يتم تحديث الصفحة تلقائيًا.",
  },
  "modal.appVersionUpdate.refreshPageInMinutes": {
    en: "Reload page in {{minutes}} minutes",
    ar: "سيتم تحديث الصفحة خلال {{minutes}} دقيقة",
  },
  "modal.appVersionUpdate.refreshPageInSeconds": {
    en: "Reload page in {{seconds}} seconds",
    ar: "سيتم تحديث الصفحة خلال {{seconds}} ثانية",
  },
  "dialog.error.btn": {
    en: "Try again",
    ar: "حاول ثانية",
  },
  "dialog.confirm.btn": {
    en: "Yes, I’m sure",
    ar: "نعم أنا متأكد",
  },
  "dialog.keep.btn": {
    en: "No, keep editing",
    ar: "لا ، استمر في التعديل",
  },
  "field.country.callingCode": {
    en: "Country code",
    ar: "كود البلد",
  },
  language: {
    en: "Language",
    ar: "اللغة",
  },
  english: {
    en: "English",
    ar: "English",
  },
  arabic: {
    en: "عربي",
    ar: "عربي",
  },
  "label.unKnownLocation": {
    en: "Unknown location",
    ar: "الموقع غير معروف",
  },
  "label.feedback.connectionErrorOccurred": {
    en: "Connection error has occurred",
    ar: "حدث خطأ بالشبكة",
  },
  [`trip.status.${TripStatus.ACTIVE}` as const]: {
    en: "Active",
    ar: "نشط",
  },
  [`trip.status.${TripStatus.CANCELLED}` as const]: {
    en: "Cancelled",
    ar: "ملغى",
  },
  [`trip.status.${TripStatus.COMPLETED}` as const]: {
    en: "Completed",
    ar: "منتهي",
  },
  [`trip.status.${TripStatus.PARTIAL_COMPLETED}` as const]: {
    en: "Partial completed",
    ar: "اكتمل جزئيًا",
  },
  [`trip.status.${TripStatus.PENDING}` as const]: {
    en: "Pending",
    ar: "معلق",
  },
};

export const getCopy = function (
  key: string,
  options?: I18nOptions,
  lang?: "ar" | "en"
): string {
  return getLocalize(labels, key, options, lang);
};

export default function useCopy() {
  const lang = useAppSelector((state) => state.settings.lang);
  const translate = useCallback(
    (key: string, options?: I18nOptions) =>
      getCopy(key, options, (lang?.toLowerCase() as "ar" | "en") || "en"),
    [lang]
  );
  return { translate };
}
