import { useTranslation } from "react-i18next";
import i18next from "i18next";
import memoize from "memoizee";

import { dayInMilliSeconds } from "~/utils/constants/dateTime";

const memoizedTranslator = memoize(
  (translate, key, options) => {
    return translate(key, options);
  },
  { max: 1000, maxAge: dayInMilliSeconds, preFetch: true }
);

// This hook to get the translation getter in all the React components.
export const useLocalization = () => {
  // eslint-disable-next-line id-length
  const { t, i18n } = useTranslation();

  const translate = (key: string, options?: unknown) => {
    return memoizedTranslator(t, key, options);
  };

  return { translate, i18n };
};

// This function to get the translation getter in any place not inside react component or hook.
export const translate = (key: string, options?: unknown) => {
  return memoizedTranslator(i18next.t, key, options);
};

// This event callback just to make sure that we cleared the cache after every time we change the language
i18next.on("languageChanged", () => {
  memoizedTranslator.clear();
});
