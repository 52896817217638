import {
  RouteDirectionality,
  StopForBaBd,
  TripStatus,
} from "~/gql/main/types.generated";

export type Trip = {
  directionality: RouteDirectionality;
  name: string;
  stops: StopForBaBd[];
  status: TripStatus;
  routeId: string;
  date: string;
  studentsOnLeaveCount: number;
  tripEndOffsetMinutes: number;
  tripStartOffsetMinutes: number;
  manualAttendanceEndOffsetMinutes: number;
  manualAttendanceStartOffsetMinutes: number;
  plannedEndTime: Date;
  plannedStartTime: Date;
  isForceStarted: boolean;
  isForceEnded: boolean;
};

type State = {
  trips: {
    [id: string]: Trip;
  };
};
const initialState: State = { trips: {} };
export default initialState;
