/* eslint-disable @typescript-eslint/no-magic-numbers */

import {
  AppType,
  ConfigType,
  GraphqlMainServiceType,
  KeysType,
  SettingsType,
} from "~/config/appConfig.d";

export const isDev = process.env.NODE_ENV === "development";

export const app: AppType = {
  appName: "Saar Driver Frontend",
  userPath: "",
};

export const keys: KeysType = {
  googleMapsApi: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API || "",
};

export const graphqlMainService: GraphqlMainServiceType = {
  hostname: process.env.NEXT_PUBLIC_SERVER_URL as string,
  protocolHttp: process.env.NEXT_PUBLIC_SERVER_HTTP_PROTOCOL || "https",
  protocolWs: process.env.NEXT_PUBLIC_SERVER_WS_PROTOCOL || "wss",
  port: Number(process.env.NEXT_PUBLIC_GRAPHQL_PORT),
  apiPrefix: `/${process.env.NEXT_PUBLIC_GRAPHQL_PREFIX}`,
};

// export const graphqlMainService: GraphqlMainServiceType = {
//   hostname: process.env.NEXT_PUBLIC_SERVER_URL,
//   protocolHttp: process.env.NEXT_PUBLIC_SERVER_HTTP_PROTOCOL || "https",
//   protocolWs: process.env.NEXT_PUBLIC_SERVER_WS_PROTOCOL || "wss",
//   port: Number(process.env.NEXT_PUBLIC_GRAPHQL_PORT),
//   apiPrefix: `/${process.env.NEXT_PUBLIC_GRAPHQL_PREFIX}`,
// };

export const settings: SettingsType = {
  timeFormatParsing: [
    "YYYY-MM-DDTHH:mm:ssZ",
    "YYYY-MM-DD HH:mm:ssZ",
    "YYYY-MM-DDTHH:mm:ss.SSSSSSSSSZ",
    "YYYY-MM-DD HH:mm:ss.SSSSSSSSSZ",
    "YYYY-MM-DDTHH:mm:ss:SSZ",
    "YYYY-MM-DDTHH:mm:ss.sssZ",
    "YYYY-MM-DD HH:mm:ss.sssZ",
  ],
  defaultDateFormat: "dd/MM/yyyy",
  defaultDateFormatReverse: "yyyy/MM/dd",
  defaultDateTimeFormat: "dd/MM/yyyy HH:mm",
  defaultTimeFormat: "HH:mm",
  defaultDayMonthFormat: "EEE dd/MM",
  urlSafeDateFormat: "yyyy-MM-dd",
  numberOfDaysInTripsSchedule: 5,
  defaultSnackBarAutoHideDuration: 6000,
};

const config: ConfigType = {
  keys,
  app,
  settings,
  graphqlMainService,
};

export default config;
